button {
  border: none;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 0.75em; 
  display: inline-block;
  text-decoration: none;
  

}
button:hover {
    cursor: pointer;
  }
button:focus {
    text-decoration: none;
    outline: none;
  }
.comment-box {
  overflow: scroll;
  max-height: 26.80em;
  max-width: 37.5rem;
  color: #fff;
  background-color: #000000;
  border-radius: 0.875rem;
  padding: 0.2rem 1rem 2rem;
}
.comment-form {
  background-color: rgb(255, 255, 255);
  border-radius: 0.9em;
  margin-bottom: 2rem;
  padding: 1rem;  
}
.comment-form-fields {
  margin-bottom: 0.25rem;
  
}
/* input, textarea {
    border: none;
    border-bottom: 1px solid #404040;
    font-size: 0.85rem;
    padding: 0.25rem 0;
    /* width: 99%; */
  /* }  */
  /* input, textarea:focus { */
    /* border-bottom-color: #00BDFC; */
    /* outline: none; */
  /* } */
  textarea { 
     /* font-style: italic;  */
     border-radius: 0.4em
   }
.comment-count {
  color: '#404040';
}
#comment-reveal {
  float: right;
  background-color:#00BDFC;
  color: #fff;
}
.comment {
  border-top: 0.125rem solid #404040;
}
.comment-body {
  font-style: italic;
  margin-left: 1rem;
}
.comment-footer {
  margin-bottom: 1rem;
} 
.float {
    /* background: rgb(253, 253, 253); */
    /* border: solid rgb(255, 255, 255) 1px; */
    float: left;
}

.half {
    width: 49%;
}
.comment-list{
    /* overflow: scroll;
    max-height: 26.80em;
    max-width: 37.5rem; */
}
  
.comment-boxs{
  padding-bottom:50px;margin-bottom:10px;
  /* overflow: scroll;
  max-height: 26.80em; */
  /* max-width: 37.5rem; */
}


.share-post-box ul.share-box li a.msg {
  background: #00b300;
}
.share-post-box ul.share-box li a.msg i {
  margin-right: 0;
}

.comment-area-box ul li .comment-boxs .comment-content {
  margin-left: 10px;
}
.comment-area-box ul.depth .comment-boxs {
  padding-left: 50px;
}
.comment-area-box ul.depth1 .comment-boxs {
  padding-left: 100px;
}
.comment-area-box ul li .comment-boxs .comment-content h4 a {
  /*color: #333333;*/
/*font-size: 13px;*/
/*font-family: 'Lato', sans-serif;*/
/*font-weight: 700;*/
margin: 0;

display: inline-block;
text-decoration: none;
transition: all 0.2s ease-in-out;
-moz-transition: all 0.2s ease-in-out;
-webkit-transition: all 0.2s ease-in-out;
-o-transition: all 0.2s ease-in-out;
color: #999999;
float: right;
font-size: 11px;
/*font-weight: 400;*/
text-transform: uppercase;
/*padding: 4px 9px;
border: 1px solid #eeeeee;
-webkit-border-radius: 2px;
-moz-border-radius: 2px;
-o-border-radius: 2px;
border-radius: 2px;*/
/*height:30px;*/
vertical-align :middle;
}

.comment-area-box ul li .comment-boxs .comment-content h4 a:hover {
background: #f44336;
color: #ffffff;
/*border: 1px solid #f44336;*/
font-size: 11px;
cursor: pointer;
}

.comment-area-box ul li .comment-boxs .comment-content h4 a:hover span{
background: #f44336;
color: #ffffff;
/*border: 1px solid #f44336;*/
font-size: 11px;
margin:0px;
cursor: pointer;
}

.comment-area-box ul li .comment-boxs .comment-content h4 a span {
margin:0px;
font-size: 11px;
cursor: pointer;

}

.replyBtn {
  background-color:#3399ff;
  color: #ffffff !important;
}
.agreeBtn {
  background-color:#41924B;
  color: #ffffff  !important;
}
.disAgreeBtn {
  background-color:#ff6600;
  color: #ffffff !important;
}
.reportAbuseBtn {
  background-color:#663399;
  color: #ffffff !important;
}

.title-section {
 margin-bottom: 18px;
}

.level0 {
  background-color:#efefef;
  margin-top:10px;
}
.level1 {
  margin-left:20px;
  background-color:#4e584e;
  margin-top:10px;
}
.level2 {
  margin-left:40px;
  background-color:#a4a6c5;
  margin-top:10px;
}
.level3 {
  margin-left:60px;
  background-color:#9c8882;
  margin-top:10px;
}
.input-group{

margin-top:30px;
}

 .comment-area-box ul {
  margin: 0;
  padding: 0;
}
 .comment-area-box ul li {
  list-style: none;
  border-radius: 15px;
}
 .comment-area-box ul li .comment-boxs {
  overflow: hidden;
  padding-bottom: 30px;
  /* border-bottom: 1px solid #f3f3f3; */
  margin-bottom: 30px;
}
 .comment-area-box ul li .comment-boxs img {
  width: 100px;
  float: left;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}
 .comment-area-box ul li .comment-boxs .comment-content {
  margin-left: 10px;
  padding-top: 10px;
}
 .comment-area-box ul li .comment-boxs .comment-content h4 {
  color: #333333;
  font-size: 13px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  margin: 0;
}
 .comment-area-box ul li .comment-boxs .comment-content h4 a {
  display: inline-block;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  color: #999999;
  float: right;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 5px 17px;
  margin: 4px;
  border: 1px solid #eeeeee;
  /* -webkit-border-radius: 2px; */
  /* -moz-border-radius: 2px; */
  /* -o-border-radius: 2px; */
  /* border-radius: 9px; */
  
}
 .comment-area-box ul li .comment-boxs .comment-content h4 a i {
  font-size: 10px;
  margin-right: 9px;
}
 .comment-area-box ul li .comment-boxs .comment-content h4 a:hover {
  background: #f44336;
  color: #ffffff;
  border: 1px solid #f44336;
}
 .comment-area-box ul li .comment-boxs .comment-content span {
  font-size: 12px;
  /* color: #999999; */
  font-family: 'Lato', sans-serif;
  display: inline-block;
  font-style: italic;
  margin: 0 0 13px;
}
 .comment-area-box ul li .comment-boxs .comment-content span i {
  margin-right: 9px;
}
 .comment-area-box ul li .comment-boxs .comment-content p {
  margin: 0 !important;
}
 .comment-area-box ul.depth .comment-boxs {
  padding-left: 100px;
}